<template>
  <div class="float">
    <va-popover
      :message="tooltip"
      placement="left"
    >
      <va-button
        small
        class="button"
        :color="color"
        :icon="icon"
        @click.prevent="clicked"
      />
    </va-popover>
  </div>
</template>

<script>
export default {
  name: 'FabButton',
  props: {
    icon: {
      type: String,
      default: 'fa fa-plus',
    },
    color: {
      type: String,
      default: 'primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  methods: {
    clicked () {
      this.$emit('clicked')
    },
  },
}
</script>

<style lang="scss">
.float {
  position: fixed;
  bottom: 70px;
  right: 70px;
  text-align: center;

  .button {
    width: 60px;
    height: 60px;
    border-radius: 50px;
  }
}
</style>
